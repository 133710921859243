import { ref } from 'vue';

const loadImage = ref();
const isImageBeingCropped = ref(false);
const isFileBeingCropped = ref(false);
const TARGET_SIZE = 800;

const dataURLtoFile = async (dataurl: any, filename: string) => {
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  let File = window.File;
  if ('File_native' in window) {
    File = (window as any)['File_native'];
  }
  return new File([u8arr], filename, { type: mime });
};

const onFileInputChange = (e: any) => {
  isImageBeingCropped.value = true;
  if (e.target?.files) {
    Object.values(e.target.files)?.forEach((file: any) => {
      try {
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);

        reader.onload = function (event: any) {
          const blob = new Blob([event.target.result]);
          window.URL = window.URL || window.webkitURL;
          const blobURL = window.URL.createObjectURL(blob);

          const image = new Image();
          image.src = blobURL;

          image.onload = async function () {
            const resizedImageDataUrl = await getResizedImage(image, file.size / 1000);
            const resizedImageFile = await dataURLtoFile(resizedImageDataUrl, file.name);
            loadImage.value = { url: resizedImageDataUrl, file: resizedImageFile };
            isImageBeingCropped.value = false;
          };
        };
      } catch (error) {
        console.error('failed to generate URl for loaded image: ', file);
      }
    });
    e.target.value = '';
  }
};

const onAllTypeFileInputChange = async (e: any) => {
  isFileBeingCropped.value = true;
  let uploadFile: any = null;
  if (e.target?.files) {
    const files = e.target.files;

    for (const file of files) {
      if (file.size > 50 * 1024 * 1024) {
        e.target.value = '';
        isFileBeingCropped.value = false;
        throw new Error('ERR_SIZE_FILE');
      }

      try {
        const reader = new FileReader();

        uploadFile = await new Promise((resolve, reject) => {
          reader.onload = function (event: any) {
            const blob = new Blob([event.target.result]);
            const blobURL = window.URL.createObjectURL(blob);
            const fileData = { url: blobURL, file };
            resolve(handleFile(fileData));
          };

          reader.onerror = function (error) {
            reject(error);
          };

          reader.readAsArrayBuffer(file);
        });

        isFileBeingCropped.value = false;
      } catch (error) {
        e.target.value = '';
        isFileBeingCropped.value = false;
        throw new Error('ERR_UPLOAD_FILE');
      }
    }

    e.target.value = '';
  }

  return uploadFile;
};

// Función para manejar los archivos en función de si son imágenes o no
const handleFile = async (fileData: { url: string; file: any }) => {
  return new Promise((resolve, reject) => {
    // Si es una imagen
    if (fileData.file.type.startsWith('image/')) {
      const image = new Image();
      image.src = fileData.url;

      image.onload = async function () {
        try {
          const resizedImageDataUrl = await getResizedImage(image, fileData.file.size / 1000);
          const resizedImageFile = await dataURLtoFile(resizedImageDataUrl, fileData.file.name);

          resolve({ url: resizedImageDataUrl, name: resizedImageFile.name, file: resizedImageFile, id: null });
        } catch (error) {
          reject(error);
        }
      };

      image.onerror = function (error) {
        reject(error);
      };
    } else {
      resolve({ url: fileData.url, name: fileData.file.name, file: fileData.file, id: null });
    }
  });
};

async function getResizedImage(img: HTMLImageElement, givenSize: number) {
  const canvas = document.createElement('canvas');
  const width = img.naturalWidth;
  const height = img.naturalHeight;
  canvas.width = width;
  canvas.height = height;

  const qualityDropRatio = TARGET_SIZE > givenSize ? 1 : TARGET_SIZE / givenSize;

  const ctx = canvas.getContext('2d');
  if (ctx) {
    ctx.drawImage(img, 0, 0, width, height);
  }
  return canvas.toDataURL('image/jpeg', qualityDropRatio);
}

function deleteLoadedImages() {
  loadImage.value = null;
}

export {
  onFileInputChange,
  getResizedImage,
  loadImage,
  isImageBeingCropped,
  deleteLoadedImages,
  onAllTypeFileInputChange,
  isFileBeingCropped,
};
